import React from 'react';

import Carousel from './Carousel.jsx';

function Events() {
  return (
    <div>
      <Carousel />
    </div>
  );
}

export default Events;
